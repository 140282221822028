<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addCertification') : $t('message.updateCertification') + ": " + certificationName }}</span>
                <HelpPopUpV2 help-page-component="AddCertification" :light="true" :no-padding="true"></HelpPopUpV2>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="certificationForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.certification') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :value="Certification__title"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Certification__title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-select
                                    :items="types"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="Certification__type"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.number') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :value="Certification__cert_no"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Certification__cert_no = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.expiryDate') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :value="Certification__cert_expiry_date"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Certification__cert_expiry_date = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addCertification()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveCertification()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddCertification",
    components: { HelpPopUpV2 },
    props: ['certificationId','certificationName','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            // rules: {
            //     title: v => !!v || this.$t('message.required'),
            // },
            types: [
                {value: 'fsc', text: 'FSC'},
                {value: 'pefc', text: 'PEFC'}
            ],
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('certification',{
            Certification__id: 'Certification.id',
            Certification__type: 'Certification.type',
            Certification__cert_no: 'Certification.cert_no',
            Certification__cert_expiry_date: 'Certification.cert_expiry_date',
            Certification__title: 'Certification.title',
        },'statePrefix'),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('certification', {
            createCertification: 'createCertification',
            getCertificationToUpdateById: 'getCertificationToUpdateById',
            resetAllCertification: 'resetAllCertification',
            resetCurrentState: 'resetCurrentState',
            resetCertificationToUpdate: 'resetCertificationToUpdate',
            updateCertification: 'updateCertification'
        }),
        addCertification() {
            if(this.$refs.certificationForm.validate()){
                this.loading.add = true
                this.createCertification()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.certificationAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.certificationNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.certificationNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.certificationNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveCertification() {
            if(this.$refs.certificationForm.validate()){
                this.loading.save = true
                this.updateCertification()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.certificationUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.certificationNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.certificationNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.certificationNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getCertificationToUpdateById(this.certificationId)
                        .then( () => {
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetCertificationToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    }
}
</script>

<style scoped>

</style>